module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-56377450-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"runtimeCaching":[{"urlPattern":{},"handler":"cacheFirst","options":{"fetchOptions":{"redirect":"follow"}}},{"urlPattern":{},"handler":"staleWhileRevalidate","options":{"fetchOptions":{"redirect":"follow"}}},{"urlPattern":{},"handler":"staleWhileRevalidate","options":{"fetchOptions":{"redirect":"follow"}}}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://309aa19468e9467da7529c9ba1041d1f@sentry.io/1455211","environment":"production"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
